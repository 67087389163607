



















































































































































import Vue from 'vue'
import axios from 'axios'

// import Round1 from '@/components/Round1.vue'
// import Round2 from '@/components/Round2.vue'
// import Round3 from '@/components/Round3.vue'
// import Round4 from '@/components/Round4.vue'
// import Round5 from '@/components/Round5.vue'

import HeaderLanding from '@/components/HeaderLanding.vue'
import HeaderLanding2 from '@/components/HeaderLanding2.vue'
import HeaderGame from '@/components/HeaderGame.vue'

import baseUrl from '@/config/Api'
import { Game } from '@/types/Game'
// import IntroVideo from './components/IntroVideo.vue'
// import Signup from './components/Signup.vue'
// import InstantPrize from './components/InstantPrize.vue'

export default Vue.extend({
	name: 'App',
	components: {
		// Round1,
		// Round2,
		// Round3,
		// Round4,
		// Round5,
		// IntroVideo,
		HeaderLanding,
		HeaderLanding2,
		HeaderGame,
		// Signup,
		// InstantPrize,
	},
	data() {
		return {
			step: 1,
			game: null as Game|null,
			signup: false,
			loading: false,
			signUpToken: null as string|null,
			signUpData: {
				email: '',
				lastName: '',
				firstName: '',
				street: '',
				postalCode: '',
				city: '',
				dateOfBirth: '',
				phone: '',
				comment: '',
				type: '',
			},
			success: false,
			gameRound: 0,
			introPlaying: false,
			secondsSpent: {
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
			} as { [key: number]: number },
			timerStart: null as any,
			timerInterval: null as any,
			gameRoundTitles: {
				1: 'FAKT ODER FAKE',
				2: 'WORTGITTER',
				3: 'BUZZER BEATER',
				4: 'SCHÄTZBEFEHL',
				5: 'GRANDE FINALE',
			},
			showBrowserModal: false,
			error: false,
		}
	},
	computed: {
		totalSeconds(): number {
			return Object.values(this.secondsSpent).reduce((a, c) => a + c, 0)
		},
	},
	mounted() {
		if (window.location.pathname === '/signup/' && window.location.search) {
			this.loading = true
			this.signup = true
			this.signUpToken = window.location.search.replace('?', '')

			axios.post(
				`${baseUrl}/signup/validate-token`,
				{ token: this.signUpToken },
			)
				.then((res) => {
					this.signUpData.email = res.data.email
					this.signUpData.type = res.data.type
					this.loading = false
				})
				.catch((e) => {
					console.log(e)
					window.location.href = '/'
				})
		}
	},
	methods: {
		submitData() {
			this.loading = true
			axios.post(
				`${baseUrl}/signup/details`,
				{ token: this.signUpToken, ...this.signUpData },
			)
				.then(() => {
					// eslint-disable-next-line no-alert
					this.success = true
				})
				.catch((e) => {
					console.log(e)
					// eslint-disable-next-line no-alert, max-len
					alert('Bei der Übermittlung der Daten ist ein Fehler aufgetreten. Möglicherweise wurden Deine Angaben bereits abgesendet. Falls nicht, klicke bitte erneut auf den Link in der erhaltenen E-Mail.')
				})
				.finally(() => {
					this.loading = false
				})
		},
		startGame() {
			axios.post(`${baseUrl}/games`).then((res) => {
				this.game = res.data.game
				this.gameRound = 1
				this.step = 4
			}).catch((e) => {
				console.log(e)
				this.error = true
			})
		},
		updateGame() {
			axios.get(
				`${baseUrl}/games/${this.game?.id}`,
				{ params: { token: this.game?.token } },
			)
				.then((res) => {
					this.game = { ...res.data, token: this.game?.token }
				})
				.catch((e) => {
					console.log(e)
					this.error = true
				})
		},
		updateSeconds() {
			const secondsPassed = Math.round((new Date().getTime() - this.timerStart) / 1000)
			this.secondsSpent[this.gameRound] = secondsPassed

			if (this.totalSeconds > 120) {
				this.stopTimer()
				this.gameRound = 6
				this.updateGame()
			}
		},
		startTimer() {
			this.timerStart = new Date()
			this.timerInterval = setInterval(this.updateSeconds, 500)
		},
		stopTimer() {
			const secondsPassed = Math.round((new Date().getTime() - this.timerStart) / 1000)
			this.secondsSpent[this.gameRound] = secondsPassed
			clearInterval(this.timerInterval)
		},
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		},
	},
})
